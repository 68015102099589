const navigationConfig = [{
    'id': 'applications',
    'title': 'Menú',
    'type': 'group',
    'icon': 'apps',
    'children': [{
        'id': 'dashboard',
        'title': 'DashBoard',
        'type': 'item',
        'icon': 'chat',
        'url': '/dashboard',
        'auth': ['master', 'rh', 'rh_not_signer', 'director', 'admin']
    },
    {
        'id': 'company-list',
        'title': 'Empresas',
        'type': 'item',
        'icon': 'business',
        'url': '/company-list',
        'auth': ['master', 'rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'user-groups',
        'title': 'Direcciones',
        'type': 'item',
        'icon': 'groups',
        'url': '/user-groups',
        'auth': ['master', 'rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'superintendencias',
        'title': 'Superintendencias',
        'type': 'item',
        'icon': 'groups',
        'url': '/superintendencias',
        'auth': ['master', 'rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'user-depart',
        'title': 'Departamentos',
        'type': 'item',
        'icon': 'list',
        'url': '/user-depart',
        'auth': ['master', 'rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'dependencias',
        'title': 'Divisiones',
        'type': 'collapse',
        'icon': 'money',
        'auth': ['rh', 'rh_not_signer', 'director', 'admin'],
        'children': [
            {
                'id': 'asignar-dependencias',
                'title': 'Asignar Divisiones',
                'type': 'item',
                'icon': 'person',
                'url': '/asignar-dependencias',
                'auth': ['rh', 'rh_not_signer', 'admin']
            },
            {
                'id': 'listar-dependencias',
                'title': 'Listado de Divisiones',
                'type': 'item',
                'icon': 'list',
                'url': '/dependencias',
                'auth': ['rh', 'rh_not_signer', 'admin']
            }
        ]
    },
    {
        'id': 'user-cargos',
        'title': 'Cargos',
        'type': 'item',
        'icon': 'list',
        'url': '/user-cargos',
        'auth': ['master', 'rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'user-patronal',
        'title': 'Patronales',
        'type': 'item',
        'icon': 'list',
        'url': '/user-patronal',
        'auth': ['master', 'rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'employees',
        'title': 'Empleados',
        'type': 'item',
        'icon': 'person',
        'url': '/employees',
        'auth': ['master', 'rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'conceptos',
        'title': 'Conceptos',
        'type': 'item',
        'icon': 'list',
        'url': '/conceptos-list',
        'auth': ['master', 'rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'equivalencias',
        'title': 'Equivalencias de Conceptos',
        'type': 'item',
        'icon': 'list',
        'url': '/equivalencias-list',
        'auth': ['master', 'rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'documents-type-list',
        'title': 'Tipos de Pagos',
        'type': 'item',
        'icon': 'list',
        'auth': ['master', 'rh', 'rh_not_signer', 'admin'],
        'url': '/documents-type-list',
    },
    {
        'id': 'salary',
        'title': 'Documentos de Haberes',
        'type': 'collapse',
        'icon': 'money',
        'auth': ['rh', 'rh_not_signer', 'director', 'funcionario', 'admin'],
        'children': [{
            'id': 'payment-receipts',
            'title': 'Agregar Recibo',
            'type': 'item',
            'icon': 'insert_drive_file',
            'url': '/payment-receipt',
            'auth': ['rh', 'rh_not_signer', 'admin']
        },
        {
            'id': 'document-list',
            'title': 'Recibos de Haberes',
            'type': 'item',
            'icon': 'document-list',
            'url': '/document-list',
            'auth': ['master', 'rh', 'rh_not_signer', 'director', 'funcionario', 'admin']
        },
        {
            'id': 'document-report',
            'title': 'Control de Recibos',
            'type': 'item',
            'icon': 'document-list',
            'url': '/document-report',
            'auth': ['rh', 'rh_not_signer', 'admin']
        },
        {
            'id': 'load-logs',
            'title': 'Log de Carga',
            'type': 'item',
            'icon': 'help_outline',
            'url': '/loadlogs',
            'auth': ['master', 'auditor', 'rh', 'rh_not_signer', 'admin']
        },
            /*{
                'id'   : 'documents',
                'title': 'Documentos',
                'type' : 'item',
                'icon' : 'insert_drive_file',
                'url'  : '/documents',
                'auth' : ['master', 'rh', 'rh_not_signer', 'director', 'funcionario', 'admin']
            }*/
        ]
    },
    {
        'id': 'chat',
        'title': 'Notificaciones',
        'type': 'collapse',
        'icon': 'chat',
        'auth': ['rh', 'rh_not_signer', 'director', 'funcionario'],
        'children': [
            {
                'id': 'Tipos de Notificaciones',
                'title': 'Tipos de Notificaciones',
                'type': 'item',
                'icon': 'document-list',
                'url': '/tipo-notificacion',
                'auth': ['rh', 'rh_not_signer']
            },
            {
                'id': 'Registrar Notificacion',
                'title': 'Registrar Notificacion',
                'type': 'item',
                'icon': 'document-list',
                'url': '/notificaciones',
                'auth': ['rh', 'rh_not_signer']
            },
            {
                'id': 'Listar Notificaciones',
                'title': 'Listar Notificaciones',
                'type': 'item',
                'icon': 'document-list',
                'url': '/notificaciones-list',
                'auth': ['funcionario', 'rh', 'rh_not_signer', 'director']
            }

        ]
    },
    {
        'id': 'permisos',
        'title': 'Permisos',
        'type': 'collapse',
        'icon': 'document-list',
        'auth': ['rh', 'rh_not_signer', 'director', 'funcionario', 'admin'],
        'children': [
            {
                'id': 'list_tipos_permisos',
                'title': 'Tipos de Permisos',
                'type': 'item',
                'icon': 'list',
                'url': '/tipos-permisos',
                'auth': ['rh', 'rh_not_signer', 'admin']
            },
            {
                'id': 'solicitud_permisos_employee',
                'title': 'Solicitar Permiso',
                'type': 'item',
                'icon': 'document-list',
                'url': '/permisos',
                'auth': ['funcionario', 'admin']
            },
            {
                'id': 'solicitud_permisos',
                'title': 'Solicitudes de Permisos',
                'type': 'item',
                'icon': 'document-list',
                'url': '/permisos-list',
                'auth': ['rh', 'rh_not_signer', 'director', 'funcionario', 'admin']
            }
        ]
    },
    {
        'id': 'vacaciones',
        'title': 'Vacaciones',
        'type': 'collapse',
        'icon': 'document-list',
        'auth': ['rh', 'rh_not_signer', 'director', 'funcionario', 'admin'],
        'children': [
            {
                'id': 'solicitud_vacacion_employee',
                'title': 'Solicitar Vacacion',
                'type': 'item',
                'icon': 'document-list',
                'url': '/vacaciones',
                'auth': ['funcionario', 'admin']
            },
            {
                'id': 'list_tipos_vacaciones',
                'title': 'Tipos de Vacaciones',
                'type': 'item',
                'icon': 'list',
                'url': '/tipos-vacaciones',
                'auth': ['rh', 'rh_not_signer', 'admin']
            },
            {
                'id': 'solicitud_vacacion',
                'title': 'Solicitudes de Vacaciones',
                'type': 'item',
                'icon': 'document-list',
                'url': '/vacaciones-list',
                'auth': ['rh', 'rh_not_signer', 'director', 'funcionario', 'admin']
            }
        ]
    },
    {
        'id': 'certificados',
        'title': 'Certificados de Trabajo',
        'type': 'collapse',
        'icon': 'document-list',
        'auth': ['rh', 'rh_not_signer', 'director', 'funcionario', 'admin'],
        'children': [
            {
                'id': 'solicitud_certificados_employee',
                'title': 'Solicitar Certificado',
                'type': 'item',
                'icon': 'document-list',
                'url': '/certificados',
                'auth': ['funcionario', 'admin']
            },
            {
                'id': 'solicitud_certificados',
                'title': 'Solicitudes de Certificados',
                'type': 'item',
                'icon': 'document-list',
                'url': '/certificados-list',
                'auth': ['rh', 'rh_not_signer', 'director', 'funcionario', 'admin']
            }

        ]
    },
    {
        'id': 'comunications',
        'title': 'Comunicaciones',
        'type': 'collapse',
        'icon': 'money',
        //'auth': ['master', 'rh', 'rh_not_signer', 'admin'],
        'auth': process.env.REACT_APP_HIDE_COMUNICATIONS ? [] : [],
        'children': [{
            'id': 'AccidentesLaborales',
            'title': 'Accidentes Laborales',
            'type': 'item',
            'icon': 'person',
            'url': '/list-accidenteslaborales'
        },
        {
            'id': 'Listar Amonestaciones',
            'title': 'Listar Amonestaciones',
            'type': 'item',
            'icon': 'person',
            'url': '/list-amonestaciones'
        },
        {
            'id': 'Registrar Amonestación',
            'title': 'Registrar Amonestación',
            'type': 'item',
            'icon': 'person',
            'auth': ['rh', 'rh_not_signer', 'admin'],
            'url': '/amonestaciones'
        },
        {
            'id': 'Abandono',
            'title': 'Abandono',
            'type': 'item',
            'icon': 'person',
            'url': '/list-abandono'
        },
        {
            'id': 'EnfermedadesProfesionales',
            'title': 'Enfermedades Profesionales',
            'type': 'item',
            'icon': 'person',
            'url': '/list-enfermedadesprofesionales'
        },
        {
            'id': 'Vacaciones',
            'title': 'Vacaciones',
            'type': 'item',
            'icon': 'person',
            'url': '/list-vacaciones'
        },
        {
            'id': 'Permisos',
            'title': 'Permisos',
            'type': 'item',
            'icon': 'person',
            'url': '/list-permisos'
        },
        {
            'id': 'Listar Suspensiones',
            'title': 'Listar Suspensiones',
            'type': 'item',
            'icon': 'person',
            'url': '/list-suspensiones'
        },
        {
            'id': 'Registrar Suspensión',
            'title': 'Registrar Suspensión',
            'type': 'item',
            'icon': 'person',
            'auth': ['rh', 'rh_not_signer', 'admin'],
            'url': '/suspensiones'
        },
        {
            'id': 'Listar Preavisos',
            'title': 'Listar Preavisos',
            'type': 'item',
            'icon': 'person',
            'url': '/list-preavisos'
        },
        {
            'id': 'Registrar Preaviso',
            'title': 'Registrar Preaviso',
            'type': 'item',
            'icon': 'person',
            'url': '/preavisos'
        },
        {
            'id': 'Ausencias',
            'title': 'Ausencias',
            'type': 'item',
            'icon': 'person',
            'url': '/list-ausencias'
        },
        {
            'id': 'Apercibimiento',
            'title': 'Apercibimiento',
            'type': 'item',
            'icon': 'person',
            'url': '/list-apercibimiento'
        },

        {
            'id': 'Listar Apercibimientos',
            'title': 'Listar Apercibimientos',
            'type': 'item',
            'icon': 'person',
            'url': '/list-apercibimiento'
        },
        {
            'id': 'Registrar Apercibimiento',
            'title': 'Registrar Apercibimiento',
            'type': 'item',
            'icon': 'person',
            'url': '/apercibimiento'
        },
        {
            'id': 'RegisterRequest',
            'title': 'Registrar solicitud',
            'type': 'item',
            'icon': 'lock',
            'url': '/form/register',
            'auth': ['master', 'rh', 'rh_not_signer', 'director', 'funcionario', 'admin']
        },
        {
            'id': 'RequestList',
            'title': 'Lista de solicitudes',
            'type': 'item',
            'icon': 'lock',
            'url': '/RequestList',
            'auth': ['master', 'rh', 'rh_not_signer', 'director', 'funcionario', 'admin']
        },
        {
            'id': 'RequestRRHH',
            'title': 'Lista de solicitudes RRHH',
            'type': 'item',
            'icon': 'lock',
            'url': '/RequestRRHH',
            'auth': ['master', 'rh', 'rh_not_signer', 'admin']
        }
        ]
    },
    {
        'id': 'user-certificate',
        'title': 'Certificado del Usuario',
        'type': 'item',
        'icon': 'help_outline',
        'auth': ['master', 'admin'],
        'url': '/user-certificate',
    },
    {
        'id': 'user-firma-holograph',
        'title': 'Firma Holografa',
        'type': 'item',
        'icon': 'person',
        'auth': ['master', 'admin'],
        'url': '/user-firma-holograph',
    },
    {
        'id': 'control_sign',
        'title': 'Control de Firmas',
        'type': 'item',
        'icon': 'edit',
        'auth': ['rh', 'admin'],
        'url': '/control-sign'
    },
    {
        'id': 'faq',
        'title': 'FAQ',
        'type': 'item',
        'icon': 'help_outline',
        'url': '/faq'
    },
    {
        'id': 'login-component',
        'title': 'Iniciar Sesión',
        'type': 'item',
        'icon': 'lock',
        'url': '/login',
        'auth': ['guest', 'admin']
    },
    {
        'id': 'email-config',
        'title': 'Configuración de E-mail',
        'type': 'collapse',
        'icon': 'email',
        'auth': ['master', 'rh', 'rh_not_signer', 'admin'],
        'children': [{
            'id': 'email-config-server',
            'title': 'Servidor de Correo',
            'type': 'item',
            'icon': 'devices',
            'url': '/email-config-server',
            'auth': ['master', 'admin']
        },
        {
            'id': 'notification-reminder-director',
            'title': 'Notificación Director',
            'type': 'item',
            'icon': 'drafts',
            'url': '/email-config/notification-reminder-director',
            'auth': ['master', 'rh', 'rh_not_signer', 'admin']
        },
        {
            'id': 'notification-reminder-employee',
            'title': 'Notificación Empleado',
            'type': 'item',

            'icon': 'drafts',
            'url': '/email-config/notification-reminder-employee',
            'auth': ['master', 'rh', 'rh_not_signer', 'admin']
        },
        ]
    },
    {
        'id': 'configuration',
        'title': 'Configuraciones',
        'type': 'item',
        'icon': 'build',
        'auth': ['master', 'admin'],
        'url': '/configuraciones',
    },
    {
        'id': 'user-maintenance',
        'title': 'Mantenimiento de Usuarios',
        'type': 'item',
        'icon': 'build',
        'auth': ['master', 'admin'],
        'url': '/user-maintenance',
    },
    {
        'id': 'logs',
        'title': 'Log de Acciones',
        'type': 'item',
        'icon': 'help_outline',
        'url': '/logs',
        'auth': ['master', 'auditor', 'rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'comunicacion',
        'title': 'Comunicación MTESS',
        'type': 'item',
        'icon': 'email',
        'url': '/comunicacion',
        'auth': ['rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'mtess-verify',
        'title': 'Consultas al MTESS',
        'type': 'collapse',
        'icon': 'help_outline',
        'auth': ['master', 'rh', 'rh_not_signer', 'admin'],
        'children': [{
            'id': 'request-hash',
            'title': 'Verificación de incersión',
            'type': 'item',
            'icon': 'help_outline',
            'url': '/logging',
            'auth': ['master', 'rh', 'rh_not_signer', 'admin']
        },
        {
            'id': 'penalty',
            'title': 'Consulta de multas',
            'type': 'item',
            'icon': 'help_outline',
            'url': '/penalty',
            'auth': ['master', 'rh', 'rh_not_signer', 'admin']
        },
        ]
    },
    ]
}];

export default navigationConfig;