import React from 'react';
//import {authRoles} from '../../auth';

export const EmailServerConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: ['master'],
    routes: [{
        path: '/email-config-server',
        component: React.lazy(() =>
            import ('./EmailServer'))
    }]
};