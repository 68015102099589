import React from 'react';
//import {authRoles} from '../../auth';

export const DependenciasUpdateConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: ['master', 'rh', 'rh_not_signer'],
    routes: [{
        path: '/dependencias-details/:id',
        component: React.lazy(() =>
            import ('./DependenciasUpdate'))
    }]
};