import React from 'react';
//import {authRoles} from '../../auth';

export const SuperintendenciaListConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: ['master', 'rh', 'rh_not_signer', 'director'],
    routes: [{
        path: '/superintendencias',
        component: React.lazy(() =>
            import ('./SuperintendenciaList'))
    }]
};