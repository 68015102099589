import React from 'react';
//import {authRoles} from '../../auth';

export const TiposPermisosUpdateConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: ['master', 'rh', 'rh_not_signer'],
    routes: [{
        path: '/tipos-permisos-details/:id',
        component: React.lazy(() =>
            import ('./TiposPermisosUpdate'))
    }]
};