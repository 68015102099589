import React from 'react';

export const PermisosListConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth : ['rh', 'rh_not_signer', 'director', 'funcionario'],
    routes  : [
        {
            path     : '/permisos-list',
            component: React.lazy(() => import('./PermisosList'))
        }
    ]
};